import React from "react";
import DdjSr2 from "../Assets/ddj-sr2.png";
import MacBook from "../Assets/macbook.webp";
import Xlr from "../Assets/xlr.webp";

const Work = () => {
  const workInfoData = [
    {
      image: DdjSr2,
      title: "DDJ-SR2",
      text: "Controlador de 2 canales que hereda el diseño y las características del equipo profesional.",
    },
    {
      image: MacBook,
      title: "MacBook Pro 13' (Mid 2012)",
      text: "Procesador i5, 16GB de Memoria RAM y 2 unidades SSD de 250GB & 1TB.",
    },
    {
      image: Xlr,
      title: "Salida Master XLR",
      text: "Salida XLR o Cannon, también cuenta con salidas y entradas RCA.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Equipamiento</p>
        <h1 className="primary-heading" style={{ textAlign: "center" }}>
          Especificaciones Técnicas
        </h1>
        <p className="primary-text">
          En este apartado podrás encontrar información sobre el Setup que
          utiliza DJ Benz.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
