import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">Conóceme</p>
        <h1 className="primary-heading">Algunas Cosas Sobre Benz</h1>
        <p className="primary-text">
          DJ Benz se caracteriza por su técnica y gran desempeño cuando está en
          el escenario.
        </p>
        <p className="primary-text">
          Desde muy pequeño, este artista comenzó a instruirse y comprender el
          arte de la música en las fiestas.
        </p>
        <div className="about-buttons-container">
          <a
            href="https://www.instagram.com/djbenz._/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <button className="secondary-button"> Contáctame</button>
          </a>

          <a
            href="https://www.instagram.com/p/CpGxl-nNNiz/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <button className="watch-video-button">
              <BsFillPlayCircleFill /> Ver un Video
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
